import { sessionServices } from '../services';
//import { closePopup, showPopup } from '../redux/actions';

export default class Message {
    public static Language: any = {};
    public store: any;
    public formatNumber(p_sStringNumber: string) {
        p_sStringNumber = p_sStringNumber.toString();

        const x = p_sStringNumber.split(',');
        let x1 = x[0];
        const x2 = x.length > 1 ? ',' + x[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1))
            // eslint-disable-next-line no-useless-concat
            x1 = x1.replace(rgx, '$1' + '.' + '$2');

        return x1 + x2;
    }

    public getMessage(code: string, param: any, culture: string) {
        if (!code)
            return '';
        code = code.toString();

        if(!culture)
            culture = this.getLanguageCode();
        let language = Message.Language[culture];
        if (typeof language == 'undefined' || typeof language[code] == 'undefined') {
            language = (window as any).Message.Language[(window as any).Message.lang];
        }

        let mess;
        if (typeof language !== 'undefined')
            mess = language[code];
        if (typeof mess == 'undefined')
            return code;
        
        if (param !== undefined && param.length > 0) {
            for (let i = 0; i < param.length; i++)
                mess = mess.replace('{' + i + '}', !param[i].toString().startsWith('0') && !isNaN(param[i]) ? this.formatNumber(param[i]) : param[i]);
        }
        return mess;
    }
    public getLanguageCode() {
        const code = sessionServices.getLanguage() || (window as any).Message.lang;
        return code;
    }
    /* public show(data: any) {
        this.store.dispatch(showPopup(data));
    }
    public close() {
        this.store.dispatch(closePopup());
    } */
}