import { AUTHEN } from '../../constants';
import { sessionServices } from '../../services'
export function login(model: any): any {
    return {
        type: AUTHEN.LOGIN,
        model: model
    }
}
export function check(): any {
    return {
        type: AUTHEN.CHECK_AUTHEN
    }
}
export function success(token: any, data: any) {
    sessionServices.saveAccessToken(token);
    return {
        type: AUTHEN.LOGIN_SUCCESS,
        data
    }
}
export function logout() {
    sessionServices.clearAccessToken();
    return {
        type: AUTHEN.LOGOUT
    }
}
