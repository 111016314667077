import React from "react";
import { IntlProvider } from 'react-intl';
import Message from './helpers/message';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { sessionServices } from "./services";
import "./assets/css/nucleo-icons.css";
import Bidding from "./views";
import "./assets/scss/admin.scss";
import 'react-toastify/dist/ReactToastify.css';

class App extends React.PureComponent<any, any> {
  componentDidMount(): void {
  }
  componentWillUnmount(): void {
    sessionServices.clearAccessToken();
  }
  public render() {
      const locale = sessionServices.getLanguage();
      const message = Message.Language[locale];
      return (
        <IntlProvider locale={locale} messages={message}>
          <Router basename="/">
            <Routes>
              <Route path='/bidding' element={<Bidding />}></Route>
              <Route path='/bidding/:tag' element={<Bidding />}></Route>
            </Routes>    
          </Router>
        </IntlProvider>
      )
  }
}
export default App;