import React from 'react';
import loader from '../helpers/loader';
import { connect } from 'react-redux';
import { check } from '../redux/actions';
import Login from '../containers/user/login';
import Bid from '../containers/bid';

class Bidding extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }
    public checkAuthen() {
        if ((window as any).tmCheckAuthen > 0) {
            clearInterval((window as any).tmCheckAuthen);
            (window as any).tmCheckAuthen = 0;
          }
          (window as any).tmCheckAuthen = setInterval(() => {
            this.props.check();
          }, 300000);
      }
    componentDidMount() {
        this.checkAuthen();
    }
    componentWillUnmount(): void {
        clearInterval((window as any).tmCheckAuthen);
    }
    
    public render() {
        const { isAuthen, params: { tag } } = this.props;
        return (
            <>
            {/* <Bid maDauThau={tag}></Bid> */}
            { isAuthen === false  && <Login maDauThau={tag}></Login> }
            { isAuthen === true && <Bid maDauThau={tag}></Bid> }
            </>
        )
    }
}
const mapStateToProps = (state: any) => {
    const { authen } = state;
    return {
      isAuthen: authen.isAuthen
    }
}
const mapDispatchToProps = {
    check
}
export default connect(mapStateToProps, mapDispatchToProps)(loader(Bidding));