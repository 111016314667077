import { sessionServices } from "../services";

export async function getAsync(url: string, credentials = 'include') {

    try {
        const token = sessionServices.getAccessToken();
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });
        const responseJson = await response.json();
        return Promise.resolve(responseJson);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("fetch async exception"));
}

export async function postAsync(url: string, body = {}, credentials = "include") {
    try {
        const token = sessionServices.getAccessToken();
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(body),
        });
        const responseJson = await response.json();
        return Promise.resolve(responseJson);
    } catch (ex) {
        return {c: -9};
    }
}
export async function postForm(url: string, body: any) {
    try {
        const token = sessionServices.getAccessToken();
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                /* 'Content-Type': 'multipart/form-data', */
                'Authorization': 'Bearer ' + token
            },
            body,
        });
        const responseJson = await response.json();
        return Promise.resolve(responseJson);
    } catch (ex) {
        return {c: -9};
    }
}

export async function getJsonAsync(url : string){
    try {
        const response = await fetch(url);
        const responseJson = await response.json();
        return Promise.resolve(responseJson);
    } catch (ex) {
        return {c: -9};
    }
}