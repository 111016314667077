import ReactDOM from 'react-dom/client';
import App from './app';
import { configureStore } from './redux/store';
import { Provider } from 'react-redux';
import Message from "./helpers/message";
//import reportWebVitals from './reportWebVitals';

function disabledEvent(e: any){
  if (e.stopPropagation){
    e.stopPropagation();
  } else if (window.event){
    window.event.cancelBubble = true;
  }
  e.preventDefault();
  return false;
}
function render () {
  (window as any).Config.lang.forEach((item: any) => {
    Message.Language[item] = {
      ...require(`./langs/${item}.json`)
    }
  });
  
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
      <Provider store={configureStore()}>
        <App />
      </Provider>
  );
}
fetch("/config/config.json").then(response => response.json())
  .then(dt => {
    (window as any).Config = dt;
    if(process.env.NODE_ENV == 'production')
    {
      console.log = console.debug = console.info = console.warn = () => {  
        //
      };
      window.addEventListener('contextmenu', (e) => { 
        e.preventDefault(); 
      }, false);
      document.addEventListener("keydown", (e) => {
          if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
            disabledEvent(e);
          }
          if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            disabledEvent(e);
          }
          if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            disabledEvent(e);
          }
          if (e.ctrlKey && e.keyCode == 85) {
            disabledEvent(e);
          }
          if (e.keyCode == 123) {
            disabledEvent(e);
          }
        }, false);
    }
    try {
       render();
    } catch(ex) {
      alert(ex);
    }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
