import { combineReducers, Reducer } from 'redux';
import authen from './authen';
export interface IApplicationState {
    authen: any;
}

const reducers: Reducer<IApplicationState> = combineReducers<IApplicationState>({
    authen
});

export default reducers;
