import { getAsync } from '../helpers/request';
import { AUTHEN } from '../constants';
import { sessionServices } from './session';

export const authenServices = {
    login,
    logout,
    check
}

async function login(model: any) {
    try {
        const { dienthoai, matkhau, maDauThau } = model;
        const url = `/api/ncc/login`;
        const body = {
            DienThoai: dienthoai,
            MatKhau: matkhau,
            MaDauThau: maDauThau
        }
        const request = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        });
        const response = await request.json();
        return Promise.resolve(response);
    } catch (ex) {
        console.error(ex);
    }
    return Promise.reject({ c: -9 });
}

function logout() {
    localStorage.removeItem(AUTHEN.ACCESS_TOKEN);
}

async function check() {
    const url = `/api/ncc/check`;
    try {
        const response = await getAsync(url);
        const { code } = response;
        if(code < 0) {
            const s =  sessionServices.getAccessToken();
            if(s !== "") {
                sessionServices.clearAccessToken();
                window.location.reload();
            }
            return;
        }
            
        return Promise.resolve(response);
    } catch (ex) {
        sessionServices.clearAccessToken();
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}