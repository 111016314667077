import React from 'react';
import { injectIntl } from 'react-intl';
import { authenServices, sessionServices } from '../../services';
import { success } from '../../redux/actions';
import { connect } from 'react-redux';
import classnames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import PerfectScrollbar from 'perfect-scrollbar';
import en from '../../assets/img/en.png';
import vi from '../../assets/img/vn.png';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";

class Login extends React.Component<any, any> {
    private input: any;
    private ps: any = null;
    private loginPanel: any;
    constructor(props: any) {
        super(props);
        this.state = {
            dienthoai: null,
            matkhau: null,
            userFocus: true,
            passFocus: false,
            showPass: false
        }
        this.input = React.createRef();
        this.loginPanel = React.createRef();
    }
    componentDidMount() {
        this.input.current.focus();
        setTimeout(() => {
            if (navigator.platform.indexOf("Win") > -1) {
                document.documentElement.classList.add("perfect-scrollbar-on");
                document.documentElement.classList.remove("perfect-scrollbar-off");
                try {
                    this.ps = new PerfectScrollbar(this.loginPanel.current);
                    this.ps.update();   
                } catch { }
                
            }
        }, 50);
       
    }
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        //this.ps.update();
    }
    componentWillUnmount(): void {
        if(this.ps !== null)
            this.ps.destroy();
    }
    public showError = (message: any) => {
        toast.error(message);
    }
    public handleLogin = async () => {
        const { dienthoai, matkhau } = this.state;
        const { intl, maDauThau } = this.props;
        if(!maDauThau) {
            toast.error(intl.formatMessage({ id: "lb103"}));
            return;
        }
        if(dienthoai === null || dienthoai.trim() === "") {
            toast.error(intl.formatMessage({ id: "lb04"}));
            return;
        }
        if(matkhau === null || matkhau.trim() === "") {
            toast.error(intl.formatMessage({ id: "lb05"}));
            return;
        }
        
        try {
            const response = await authenServices.login({ dienthoai, matkhau, maDauThau });
            const { code, message, token, data } = response;
            if (code < 0) {
                this.showError(intl.formatMessage({id: message}));
                return;
            }
            this.props.success(token, data);
            if(window.location.hash === "") {
                window.location.hash = "#/dau-thau";
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    public handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [e.target.name]: (e.target.type === "checkbox" ? e.target.checked : e.target.value),
            isError: false,
            message: null
        });
    }
    public handleKeyUp = (e: any) => {
        e.preventDefault();
        if (e.keyCode === 13) {
          const { dienthoai, matkhau } = this.state;
          if(dienthoai === '' || dienthoai === undefined || dienthoai == null) {
            document.getElementsByName("dienthoai")[0].focus();
            return;
          }
          if(matkhau === '' || matkhau === undefined || matkhau == null) {
            document.getElementsByName("matkhau")[0].focus();
            return;
          }
          this.handleLogin();
        }
    }
    public userFocusChange = (t: any) => {
        this.setState({ userFocus: t });
    }
    public passFocusChange = (t: any) => {
        this.setState({ passFocus: t });
    }
    handleChangeShowPass = () => {
        const { showPass } = this.state;
        this.setState({
            showPass: !showPass
        })
    }
    handleChangeLang = (l: any) => {
        sessionServices.saveLanguage(l);
        window.location.reload();
    }
    public render() {
        const { userFocus, passFocus, showPass } = this.state;
        const { intl } = this.props;
        return (
            <div className="wrapper wrapper-full-page" ref={this.loginPanel}>
                <div className="fixed-background"></div>
                <div className="full-page login-page">
                    <div className="content">
                        <Container>
                            <Col className="ml-auto mr-auto" lg="4" md="6">
                                <Form className="form">
                                    <Card className="card-login card-white">
                                        <CardHeader>
                                            <CardTitle tag="h3" className='login-t'>{intl.formatMessage({id: "lb122"})}</CardTitle>
                                            <div className='change-lang'>
                                                <img src={en} onClick={this.handleChangeLang.bind(this, "en")} alt="Tiếng Anh" />
                                                <img src={vi} onClick={this.handleChangeLang.bind(this, "vi")} alt="Tiếng Việt" />
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <InputGroup className={classnames({"input-group-focus": userFocus,})}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i className="tim-icons icon-single-02" /></InputGroupText>
                                                </InputGroupAddon>
                                                <input className='form-control' placeholder={ intl.formatMessage({ id: "lb40" })}
                                                        type="text" name='dienthoai' id='dienthoai'
                                                        onFocus={this.userFocusChange.bind(this, true)}
                                                        onBlur={this.userFocusChange.bind(this, false)}
                                                        onChange={this.handleInputChange}
                                                        onKeyUp={this.handleKeyUp}
                                                        ref={this.input}
                                                />
                                            </InputGroup>
                                            <InputGroup className={classnames({"input-group-focus": passFocus,})}>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-lock-circle" />
                                                        <i className={"eye" + (showPass ? " off" : "")} onClick={this.handleChangeShowPass}></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={ intl.formatMessage({ id: "lb03" })}
                                                        type={showPass ? "text" : "password"} 
                                                        name='matkhau' id='matkhau'
                                                        onFocus={this.passFocusChange.bind(this, true)}
                                                        onBlur={this.passFocusChange.bind(this, false)}
                                                        onKeyUp={this.handleKeyUp}
                                                        onChange={this.handleInputChange}
                                                />
                                            </InputGroup>
                                        </CardBody>
                                        <CardFooter>
                                            <Button block className="mb-3" color="primary" onClick={this.handleLogin} size="lg">
                                                {intl.formatMessage({ id: "lb01"})}
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Container>
                    </div>
                </div>
                <ToastContainer position="top-center" hideProgressBar={true} autoClose={2000} />
            </div>
        )
    }
}
const mapDispatchToProps = {
    success
}
export default connect(null, mapDispatchToProps)(injectIntl(Login));