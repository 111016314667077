import { getAsync } from "../helpers/request";

export const dataServices = {
    getHang
}
async function getHang(dauThauId: any = null) {
    let url = `/api/hhdt/get?id=${dauThauId}`;
    try {
        const response = await getAsync(url);
        const { code, message } = response;
        if(code < 0 && message === "Unauthorized") {
            window.location.reload();
            return;
        }
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}