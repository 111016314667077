

import { AUTHEN } from "../../constants";

export const defaultState: any = {
  isAuthen: false,
  dauthau: null
};

export default function authen(state = defaultState, action: any) {
  switch (action.type) {
    case AUTHEN.LOGIN_SUCCESS: {
      return {
        isAuthen: true,
        dauthau: action.data
      }
    }
    case AUTHEN.LOGOUT: {
      return {
        isAuthen: false
      }
    }
    default:
      return state;
  }
}