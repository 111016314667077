import { getAsync, postAsync } from "../helpers/request";

export const chaoHangServices = {
    get,
    insert
}
async function get(dauThauId: any = null, nhaCungCapId = null, lan = -1) {
    let url = `/api/ch/get?dauThauId=${dauThauId}&nhaCungCapId=${nhaCungCapId}&lan=${lan}`;
    try {
        const response = await getAsync(url);
        const { code, message } = response;
        if(code < 0 && message === "Unauthorized") {
            window.location.reload();
            return;
        }
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}
async function insert(listChao: any) {
    let url = `/api/ch/insert`;

    try {
        const response = await postAsync(url, listChao);
        const { code, message } = response;
        if(code < 0 && message === "Unauthorized") {
            window.location.reload();
            return;
        }
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}