import { AUTHEN, CONFIG } from "../constants";

export const sessionServices = {
    saveAccessToken,
    getAccessToken,
    clearAccessToken,
    saveLanguage,
    getLanguage
}
function saveAccessToken(token: string) {
    sessionStorage.setItem(AUTHEN.ACCESS_TOKEN, token);
}
function getAccessToken() {
    return sessionStorage.getItem(AUTHEN.ACCESS_TOKEN) || "";
}
function clearAccessToken() {
    sessionStorage.removeItem(AUTHEN.ACCESS_TOKEN);
}
function saveLanguage(code: string) {
    sessionStorage.setItem(CONFIG.STORAGE_LANGUAGE_CODE, code);
}

function getLanguage() {
    let lang: any = sessionStorage.getItem(CONFIG.STORAGE_LANGUAGE_CODE);
    if(!lang)
    {
        lang = (window as any).Config.default;
    }
    return lang;
}