import { getAsync, postAsync } from "../helpers/request";

export const baoGiaServices = {
    get,
    insert,
    max,
    del
}
async function get(dauThauId: any = null, nhaCungCapId = null, lan = -1) {
    let url = `/api/bg/get?dauThauId=${dauThauId}&nhaCungCapId=${nhaCungCapId}&lan=${lan}`;
    try {
        const response = await getAsync(url);
        const { code, message } = response;
        if(code < 0 && message === "Unauthorized") {
            window.location.reload();
            return;
        }
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}
async function insert(listHang: any) {
    let url = `/api/bg/insert`;

    try {
        const response = await postAsync(url, listHang);
        const { code, message } = response;
        if(code < 0 && message === "Unauthorized") {
            window.location.reload();
            return;
        }
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}
async function del(dauThauId: any, nhaCungCapId: any, lan: any) {
    let url = `/api/bg/hide?dauThauId=${dauThauId}&nhaCungCapId=${nhaCungCapId}`;
    const body = {
        dauThauId,
        nhaCungCapId,
        lan
    }
    try {
        const response = await postAsync(url, body);
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}
async function max(dauThauId: any = null, nhaCungCapId = null) {
    let url = `/api/bg/max?dauThauId=${dauThauId}&nhaCungCapId=${nhaCungCapId}`;
    try {
        const response = await getAsync(url);
        const { code, message } = response;
        if(code < 0 && message === "Unauthorized") {
            window.location.reload();
            return;
        }
        return Promise.resolve(response);
    } catch (ex) {
        console.log(ex);
    }
    return Promise.reject(new Error("-9"))
}