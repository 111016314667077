import { takeLatest, put, call } from "redux-saga/effects";
import { SagaIterator } from '@redux-saga/core';
import { AUTHEN } from "../../constants";
import { authenServices, sessionServices } from "../../services";
export const authen = [
    takeLatest(AUTHEN.CHECK_AUTHEN, check)
]
function* check(): SagaIterator {
    try {
        const response: any = yield call(authenServices.check);
        const { code, data } = response;
        if (code < 0) {
            yield put({ type: AUTHEN.LOGOUT });
            clearInterval((window as any).tmCheckAuthen);
            return;
        }
        yield put({ type: AUTHEN.LOGIN_SUCCESS, data: data })

    } catch (ex) {
        sessionServices.clearAccessToken();
        yield put({ type: AUTHEN.LOGOUT })
    }
}