export class AUTHEN {
    public static readonly LOGIN = "LOGIN";
    public static readonly LOGOUT = "LLOGOUTOGIN";
    public static readonly LOGIN_SUCCESS = "LOGIN_SUCCESS";
    public static readonly CHECK_AUTHEN = "CHECKAUTHEN";
    public static readonly ACCESS_TOKEN = "ACCESS_TOKEN";
}

export class CONFIG {
    public static readonly STORAGE_LANGUAGE_CODE = "STORAGE_LANGUAGE_CODE";
}