import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Col, Input } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { dataServices, baoGiaServices, chaoHangServices, sessionServices } from '../../services';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import classnames from 'classnames';
import { utils } from '../../utils';
import ReactDatetime from 'react-datetime';
import Swal from 'sweetalert2';

const menu = [
    { id: 1, label: "lb107", icon: "tim-icons icon-single-02"},
    { id: 2, label: "lb108", icon: "tim-icons icon-basket-simple"},
    { id: 3, label: "lb109", icon: "tim-icons icon-cart"},
    { id: 4, label: "lb110", icon: "tim-icons icon-check-2"}
];
class Bid extends React.Component<any, any> {
    private docRef: any;
    private lang = "vi";
    constructor(props: any) {
        super(props);
        this.state = {
            inputName: '',
            final: false,
            step: 1,
            listHang: null,
            hang: null,
            lan: 0,
            listChao: [
                {
                    id: 1,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    luongChao: '',
                    giaChao: '',
                    mieuTa: '',
                    lan: 0
                },
                {
                    id: 2,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    luongChao: '',
                    giaChao: '',
                    mieuTa: '',
                    lan: 0
                },
                {
                    id: 3,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    luongChao: '',
                    giaChao: '',
                    mieuTa: '',
                    lan: 0
                },
                {
                    id: 4,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    luongChao: '',
                    giaChao: '',
                    mieuTa: '',
                    lan: 0
                },
                {
                    id: 5,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    luongChao: '',
                    giaChao: '',
                    mieuTa: '',
                    lan: 0
                },
                {
                    id: 6,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    luongChao: '',
                    giaChao: '',
                    mieuTa: '',
                    lan: 0
                },
                /* {
                    id: 7,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    giaChao: '',
                    lan: 0
                },
                {
                    id: 8,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    giaChao: '',
                    lan: 0
                },
                {
                    id: 9,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    giaChao: '',
                    lan: 0
                },
                {
                    id: 10,
                    maHang: '',
                    tenHang: '',
                    donVi: '',
                    xuatXu: '',
                    ngayHetHan: '',
                    giaChao: '',
                    lan: 0
                } */
            ]
        }
        this.docRef = React.createRef();
        this.lang = sessionServices.getLanguage();
    }
    componentDidMount() {
        this.getHang();      
        this.getLan();  
    }
    componentWillUnmount(): void {
    }
    public getLan = async () => {
        const { dauthau: { dauThauId, nhaCungCapId } } = this.props;
        const resp = await baoGiaServices.max(dauThauId, nhaCungCapId);
        const { code, data } = resp;
        if(code === 0) {
            this.setState({ lan: data + 1 });
        }
    }
    public getHang =async () => {
        const { dauthau: { dauThauId, nhaCungCapId } } = this.props;
        const resp = await dataServices.getHang(dauThauId);
        const { code, data } = resp;
        if(code === 0) {
            const a = [];
            for(const d of data) {
                const s = {
                    dauThauId: dauThauId,
                    nhaCungCapId: nhaCungCapId,
                    hangHoaId: d.hangHoaId,
                    maVatTuHangHoa: d.maVatTuHangHoa,
                    tenVatTuHangHoa: d.tenVatTuHangHoa,
                    donViTinh: d.tenDonVi,
                    soLuong: d.soLuong,
                    ghiChu: d.mieuTa,
                    maHangNhaCungCap: '',
                    xuatXu: '',
                    donGia: '',
                    ngayHetHan: '',
                    luongChao: '',
                    thoiGianGiao: '',
                    mieuTa: '',
                    id: d.id,
                    lan: 1
                }
                a.push(s);
            }
            this.setState({ listHang: a, hang: data });
        }
    }
    public renderHeader = () => {
        const { intl } = this.props;
        const { step } = this.state;
        const divide = 25;
        let per = divide * step;
        per = per > 100 ? 100 : per;
        return (
            <div data-background-color="blue" className="text-center card-header">
                <h3 className="card-title">{ intl.formatMessage({ id: "lb105" }).toUpperCase() }</h3>
                <h3 className="description">{ intl.formatMessage({ id: "lb106" }) }</h3>
                <div className="wizard-navigation" id="divNav">
                    <div className="progress-with-circle">
                        <div className="progress-bar" role="progressbar" style={{width: per + "%" }}></div>
                    </div>
                    <ul className="nav nav-pills">
                        {
                            menu.map((v: any, ix: any) => {
                                return (
                                    <li className="nav-item" style={{width: divide + "%" }} key={v.id}>
                                        <a className={ (step === v.id ? "active " : "") + "checked nav-link"}>
                                            <i className={v.icon}></i>
                                            <p>{ intl.formatMessage({ id: v.label }) }</p>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    
                    </ul>
                </div>
            </div>
        )
    }
    public changeStep = (s: any) => {
        const { step, listHang, listChao } = this.state;
        const { intl } = this.props;

        if(step === 3 && s > 0) {
            let b = false;
            for(const s of listHang) {
                if(s.donGia !== "") {
                    b = true;
                    break;
                }
            }
            if(!b) {
                for(const s of listChao) {
                    if(s.maHang.trim() !== "" && s.tenHang.trim() !== "" && s.giaChao !== "") {
                        b = true;
                        break;
                    }
                }
            }
            if(!b) {
                toast.error(intl.formatMessage({ id: "lb115"}));
                return;
            }
        }
        this.setState({ step: step + s }, () => {
            document.getElementsByTagName("html")[0].scrollTop = 0;
        });
    }
    public renderOne = () => {
        const { dauthau, intl } = this.props;
        const { hang } = this.state;
        const head = [
            { text: intl.formatMessage({id: "lb71"}), key: "tenVatTuHangHoa" },
            { text: intl.formatMessage({id: "lb60"}), key: "tenDonVi" },
            { text: intl.formatMessage({id: "lb95"}), key: "soLuong" },
            { text: intl.formatMessage({id: "lb124"}), key: "ghiChu" },
            { text: intl.formatMessage({id: "lb130"}), key: "thoiGianGiao" }
        ];
        return (
            <>
            <div className="justify-content-left mt-3">
                <div className="content-bid">{ intl.formatMessage({id: "lb119"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ dauthau ? dauthau.tenNhaCungCap : "" }</span>
                </div>
                <div className="content-bid-n" dangerouslySetInnerHTML={{ __html: dauthau ? (this.lang === "en" ? dauthau.noiDungEn :  dauthau.noiDung) : "" }}>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb78"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ utils.formDateTimedmYhms(dauthau.ngayHieuLuc) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb79"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ utils.formDateTimedmYhms(dauthau.ngayHetHan) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb125"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className="content-bid-n" dangerouslySetInnerHTML={{ __html: dauthau ? (this.lang === "en" ? dauthau.dieuKienGiaoHangEn : dauthau.dieuKienGiaoHang) : "" }}></div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb126"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ (this.lang === "en" ? dauthau.dieuKienThanhToanEn : dauthau.dieuKienThanhToan) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb127"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ (this.lang === "en" ? dauthau.chungTuEn : dauthau.chungTu) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb128"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ (this.lang === "en" ? dauthau.cuocVanTaiEn : dauthau.cuocVanTai) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb123"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ (this.lang === "en" ? dauthau.mieuTaEn : dauthau.mieuTa) }</span>
                </div>
            </div>
            <h5 className="info-text">{ intl.formatMessage({ id: "lb91" }) }</h5>
            <div className="justify-content-left mt-5 row card-body">
                <Table className='tablesorter table'>
                        <Thead className="text-primary">
                            <Tr>
                                <Th>#</Th>
                                { head.map((v) => {
                                    return <Th className={classnames(
                                        "header"
                                        )}
                                        key={v.key}>{v.text}</Th>
                                }) }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                hang && hang.map( (v: any, ix: any) => {
                                    return (
                                    <Tr key={ix}>
                                        <Td>{ix + 1}</Td>
                                        <Td>{v.tenVatTuHangHoa}</Td>
                                        <Td>{v.tenDonVi}</Td>
                                        <Td>{utils.formatNumber(v.soLuong)}</Td>
                                        <Td>{ v.mieuTa ? v.mieuTa : " "}</Td>
                                        <Td>{ v.thoiGianGiao ? v.thoiGianGiao : " " }</Td>
                                    </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
            </div>
            </>
        )
    }
    public handleAmountChange = (e:  any) => { 
        const name = e.target.name;
        const { listHang } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(parseInt(name.replace("donGia", "")) === s.id) {
                const v: any = e.target.value;
                //s.donGia  = v.replace(/\D/g, "").replace(/^(0+|0+[1-9]*(?=0$))/mg, "");
                s.donGia = utils.formatNumber(e.target.value.replace(/\D/g,"").replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, ''));
                break;
            }
        }
        this.setState({
            listHang: arr
        });
    }
    public handleLuongChange = (e:  any) => { 
        const name = e.target.name;
        const { listHang } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(parseInt(name.replace("luongChao", "")) === s.id) {
                const v: any = e.target.value;
                s.luongChao = utils.formatNumber(e.target.value.replace(/\D/g,"").replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, ''));
                break;
            }
        }
        this.setState({
            listHang: arr
        });
    }
    public handleMaChange = (e: any) => { 
        const name = e.target.name;
        const { listHang } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(parseInt(name.replace("maHangNhaCungCap", "")) === s.id) {
                s.maHangNhaCungCap = e.target.value;
                break;
            }
        }
        this.setState({
            listHang: arr
        });
    }
    public handleXuatXuChange = (e: any) => { 
        const name = e.target.name;
        const { listHang } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(parseInt(name.replace("xuatXu", "")) === s.id) {
                s.xuatXu = e.target.value;
                break;
            }
        }
        this.setState({
            listHang: arr
        });
    }
    public handleMieuTaChange = (e: any) => { 
        const name = e.target.name;
        const { listHang } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(parseInt(name.replace("mieuTa", "")) === s.id) {
                s.mieuTa = e.target.value;
                break;
            }
        }
        this.setState({
            listHang: arr
        });
    }
    public handleFocus = (e: any) => {
        this.setState({ inputName: e.target.name });
    }
    public handleThoiGianGiaoChange = (e: any) => { 
        const name = e.target.name;
        const { listHang } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(parseInt(name.replace("thoiGianGiao", "")) === s.id) {
                s.thoiGianGiao = e.target.value;
                break;
            }
        }
        this.setState({
            listHang: arr
        });
    }
    public dateClose = (m: any) => { 
        const { listHang, inputName } = this.state;
        const arr = [...listHang];
        for(const s of arr) {
            if(inputName ===  "ngayHetHan" + s.id)
                s.ngayHetHan = m._d;
        }
        this.setState({
            listHang: arr
        });
    }
    public renderTwo = () => {
        const { intl } = this.props;
        const { listHang } = this.state;
        const head = [
            { text: intl.formatMessage({id: "lb71"}), key: "tenVatTuHangHoa" },
            { text: intl.formatMessage({id: "lb60"}), key: "tenDonVi" },
            { text: intl.formatMessage({id: "lb95"}), key: "soLuong" },
            { text: intl.formatMessage({id: "lb124"}), key: "ghiChu" },
            { text: intl.formatMessage({id: "lb113"}), key: "maHang" },
            { text: intl.formatMessage({id: "lb131"}), key: "luongChao" },
            { text: intl.formatMessage({id: "lb112"}), key: "donGia" },
            { text: intl.formatMessage({id: "lb114"}), key: "xuatXu" },
            { text: intl.formatMessage({id: "lb129"}), key: "ngayHetHan" },
            { text: intl.formatMessage({id: "lb130"}), key: "thoiGianGiao" },
            { text: intl.formatMessage({id: "lb58"}), key: "mieuTa" }
        ];
        return (
            <>
            <h5 className="info-text">{ intl.formatMessage({ id: "lb91" }) }</h5>
            <div className="justify-content-left mt-5 row card-body">
                <Table className='tablesorter table'>
                            <Thead className="text-primary">
                                <Tr>
                                    <Th>#</Th>
                                    { head.map((v) => {
                                        return <Th className={classnames(
                                            "header"
                                          )}
                                          key={v.key}>{v.text}</Th>
                                    }) }
                                </Tr>
                            </Thead>
                            <Tbody>
                            {
                                    listHang && listHang.map( (v: any, ix: any) => {
                                        return (
                                        <Tr key={ix}>
                                            <Td>{ix + 1}</Td>
                                            <Td>{v.tenVatTuHangHoa}</Td>
                                            <Td>{v.donViTinh}</Td>
                                            <Td>{utils.formatNumber(v.soLuong)}</Td>
                                            <Td>{v.ghiChu ? v.ghiChu : " "}</Td>
                                            <Td>
                                                <Input type="text" name={'maHangNhaCungCap' + v.id } id={'maHangNhaCungCap' + v.id } onChange={this.handleMaChange} value={v.maHangNhaCungCap} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'luongChao' + v.id } id={'luongChao' + v.id } onChange={this.handleLuongChange} value={v.luongChao} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'donGia' + v.id } id={'donGia' + v.id } onChange={this.handleAmountChange} value={v.donGia} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'xuatXu' + v.id } id={'xuatXu' + v.id } onChange={this.handleXuatXuChange} value={v.xuatXu} />
                                            </Td>
                                            <Td>
                                            <ReactDatetime
                                                value={v.ngayHetHan !== null ? v.ngayHetHan : ''}
                                                inputProps={{
                                                    className: "form-control",
                                                    name: "ngayHetHan" + v.id,
                                                    id: "ngayHetHan" + v.id,
                                                    onFocus: this.handleFocus
                                                }}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                closeOnSelect={true}
                                                onChange={this.dateClose}
                                            />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'thoiGianGiao' + v.id } id={'thoiGianGiao' + v.id } onChange={this.handleThoiGianGiaoChange} value={v.thoiGianGiao} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'mieuTa' + v.id } id={'mieuTa' + v.id } onChange={this.handleMieuTaChange} value={v.mieuTa} />
                                            </Td>
                                        </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
            </div>
            </>
        )
    }
    public handleGiaChaoChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("giaChao", "")) === s.id) {
                s.giaChao = utils.formatNumber(e.target.value.replace(/\D/g,"").replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, ''));
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public handleLuongChaoChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("luongChao", "")) === s.id) {
                s.luongChao = utils.formatNumber(e.target.value.replace(/\D/g,"").replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, ''));
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public handleMaHangChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("maHang", "")) === s.id) {
                s.maHang = e.target.value;
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public handleTenHangChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("tenHang", "")) === s.id) {
                s.tenHang = e.target.value;
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public handleDonViChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("donVi", "")) === s.id) {
                s.donVi = e.target.value;
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public handleXuatXuChaoChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("xuatXuChao", "")) === s.id) {
                s.xuatXu = e.target.value;
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public handleMieuTaChaoChange = (e: any) => { 
        const name = e.target.name;
        const { listChao } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(parseInt(name.replace("mieuTaChao", "")) === s.id) {
                s.mieuTa = e.target.value;
                break;
            }
        }
        this.setState({
            listChao: arr
        });
    }
    public dateChaoClose = (m: any) => { 
        const { listChao, inputName } = this.state;
        const arr = [...listChao];
        for(const s of arr) {
            if(inputName ===  "ngayHetHanChao" + s.id)
                s.ngayHetHan = m._d;
        }
        this.setState({
            listChao: arr
        });
    }
    public renderThree = () => {
        const { intl } = this.props;
        const { listChao } = this.state;
        const head = [
            { text: intl.formatMessage({id: "lb70"}), key: "maHang" },
            { text: intl.formatMessage({id: "lb71"}), key: "tenHang" },
            { text: intl.formatMessage({id: "lb60"}), key: "donVi" },
            { text: intl.formatMessage({id: "lb131"}), key: "luongChao" },
            { text: intl.formatMessage({id: "lb112"}), key: "giaChao" },
            { text: intl.formatMessage({id: "lb114"}), key: "xuatXu" },
            { text: intl.formatMessage({id: "lb129"}), key: "ngayHetHan" },
            { text: intl.formatMessage({id: "lb58"}), key: "mieuTa" }
        ];
        return (
            <>
            <h5 className="info-text">{ intl.formatMessage({ id: "lb116" }) }</h5>
            <div className="justify-content-left mt-5 row card-body">
                <Table className='tablesorter table'>
                            <Thead className="text-primary">
                                <Tr>
                                    <Th>#</Th>
                                    { head.map((v) => {
                                        return <Th className={classnames(
                                            "header"
                                          )}
                                          key={v.key}>{v.text}</Th>
                                    }) }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    listChao.map( (v: any, ix: any) => {
                                        return (
                                        <Tr key={ix}>
                                            <Td>{ix + 1}</Td>
                                            <Td>
                                                <Input type="text" name={'maHang' + v.id } id={'maHang' + v.id } onChange={this.handleMaHangChange} value={v.maHang} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'tenHang' + v.id } id={'tenHang' + v.id } onChange={this.handleTenHangChange} value={v.tenHang} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'donVi' + v.id } id={'donVi' + v.id } onChange={this.handleDonViChange} value={v.donVi} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'luongChao' + v.id } id={'luongChao' + v.id } onChange={this.handleLuongChaoChange} value={v.luongChao} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'giaChao' + v.id } id={'giaChao' + v.id } onChange={this.handleGiaChaoChange} value={v.giaChao} />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'xuatXuChao' + v.id } id={'xuatXuChao' + v.id } onChange={this.handleXuatXuChaoChange} value={v.xuatXu} />
                                            </Td>
                                            <Td>
                                            <ReactDatetime
                                                value={v.ngayHetHan !== null ? v.ngayHetHan : ''}
                                                inputProps={{
                                                    className: "form-control",
                                                    name: "ngayHetHanChao" + v.id,
                                                    id: "ngayHetHanChao" + v.id,
                                                    onFocus: this.handleFocus
                                                }}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                closeOnSelect={true}
                                                onChange={this.dateChaoClose}
                                            />
                                            </Td>
                                            <Td>
                                                <Input type="text" name={'mieuTaChao' + v.id } id={'mieuTaChao' + v.id } onChange={this.handleMieuTaChaoChange} value={v.mieuTa} />
                                            </Td>
                                        </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
            </div>
            </>
        )
    }
    public renderFour = () => {
        const { intl, dauthau } = this.props;
        const { listHang, listChao } = this.state;
        const head = [
            { text: intl.formatMessage({id: "lb71"}), key: "tenVatTuHangHoa" },
            { text: intl.formatMessage({id: "lb60"}), key: "tenDonVi" },
            { text: intl.formatMessage({id: "lb95"}), key: "soLuong" },
            { text: intl.formatMessage({id: "lb124"}), key: "ghiChu" },
            { text: intl.formatMessage({id: "lb113"}), key: "maHang" },
            { text: intl.formatMessage({id: "lb131"}), key: "luongChao" },
            { text: intl.formatMessage({id: "lb112"}), key: "donGia" },
            { text: intl.formatMessage({id: "lb114"}), key: "xuatXu" },
            { text: intl.formatMessage({id: "lb129"}), key: "ngayHetHan" },
            { text: intl.formatMessage({id: "lb130"}), key: "thoiGianGiao" },
            { text: intl.formatMessage({id: "lb58"}), key: "mieuTa" }
        ];
        const head_ = [
            { text: intl.formatMessage({id: "lb70"}), key: "maHang" },
            { text: intl.formatMessage({id: "lb71"}), key: "tenHang" },
            { text: intl.formatMessage({id: "lb60"}), key: "donVi" },
            { text: intl.formatMessage({id: "lb114"}), key: "xuatXu" },
            { text: intl.formatMessage({id: "lb131"}), key: "luongChao" },
            { text: intl.formatMessage({id: "lb112"}), key: "giaChao" },
            { text: intl.formatMessage({id: "lb129"}), key: "ngayHetHan" },
            { text: intl.formatMessage({id: "lb58"}), key: "mieuTa" }
        ];
        let b = false;
        for(const s of listChao) {
            if(s.maHang.trim() !== "" && s.tenHang.trim() !== "" && s.giaChao !== "") {
                b = true;
                break;
            }
        }
        return (
            <>
            <div className="justify-content-left mt-3">
                <div className="content-bid">{ intl.formatMessage({id: "lb119"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ dauthau ? dauthau.tenNhaCungCap : "" }</span>
                </div>
                <div className="content-bid-n" dangerouslySetInnerHTML={{ __html: dauthau ? dauthau.noiDung : "" }}>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb78"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ utils.formDateTimedmYhms(dauthau.ngayHieuLuc) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb79"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ utils.formDateTimedmYhms(dauthau.ngayHetHan) }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb125"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div className="content-bid-n" dangerouslySetInnerHTML={{ __html: dauthau ? dauthau.dieuKienGiaoHang : "" }}></div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb126"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ dauthau.dieuKienThanhToan }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb127"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ dauthau.chungTu }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb128"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ dauthau.cuocVanTai }</span>
                </div>
                <div className="content-bid">
                    { intl.formatMessage({id: "lb123"}) }: &nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='text-bold'>{ dauthau.mieuTa }</span>
                </div>
            </div>
            <h5 className="info-text">{ intl.formatMessage({ id: "lb91" }) }</h5>
            <div className="justify-content-left mt-5 row card-body">
                <Table className='tablesorter table'>
                        <Thead className="text-primary">
                            <Tr>
                                <Th>#</Th>
                                { head.map((v) => {
                                    return <Th className={classnames(
                                        "header"
                                        )}
                                        key={v.key}>{v.text}</Th>
                                }) }
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                listHang.map( (v: any, ix: any) => {
                                    return (
                                    <Tr key={ix}>
                                        <Td>{ ix + 1}</Td>
                                        <Td>{ v.tenVatTuHangHoa}</Td>
                                        <Td>{ v.donViTinh}</Td>
                                        <Td>{ utils.formatNumber(v.soLuong)}</Td>
                                        <Td>{ v.ghiChu ? v.ghiChu : " "}</Td>
                                        <Td>{ v.maHangNhaCungCap ? v.maHangNhaCungCap : " " }</Td>
                                        <Td>{ v.luongChao ? v.luongChao : " " }</Td>
                                        <Td>{ v.donGia ? v.donGia: " " }</Td>
                                        <Td>{ v.xuatXu ? v.xuatXu : " "}</Td>
                                        <Td>{ v.ngayHetHan ? utils.formDateTimedmY(v.ngayHetHan) : " " }</Td>
                                        <Td>{ v.thoiGianGiao ? v.thoiGianGiao : " " }</Td>
                                        <Td>{ v.mieuTa ? v.mieuTa : " "}</Td>
                                    </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
            </div>
            { b && <>
                <h5 className="info-text">{ intl.formatMessage({ id: "lb116" }) }</h5>
            <div className="justify-content-left mt-5 row card-body">
                <Table className='tablesorter table'>
                            <Thead className="text-primary">
                                <Tr>
                                    <Th>#</Th>
                                    { head_.map((v) => {
                                        return <Th className={classnames(
                                            "header"
                                          )}
                                          key={v.key}>{v.text}</Th>
                                    }) }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    listChao.map( (v: any, ix: any) => {
                                        if(v.maHang.trim() !== "" && v.tenHang.trim() !== "" && v.giaChao !== "") 
                                        return (
                                        <Tr key={ix}>
                                            <Td>{ix + 1}</Td>
                                            <Td>{v.maHang ? v.maHang: " "}</Td>
                                            <Td>{v.tenHang ? v.tenHang : " "}</Td>
                                            <Td>{v.donVi ? v.donVi: " "}</Td>
                                            <Td>{v.luongChao ? v.luongChao : " "}</Td>
                                            <Td>{v.giaChao ? v.giaChao: " "}</Td>
                                            <Td>{v.xuatXu ? v.xuatXu : " "}</Td>
                                            <Td>{ v.ngayHetHan ? utils.formDateTimedmY(v.ngayHetHan) : " "  }</Td>
                                            <Td>{v.mieuTa ? v.mieuTa : " "}</Td>
                                        </Tr>
                                        )
                                        else return null;
                                    })
                                }
                            </Tbody>
                </Table>
            </div>
            </> }
            </>
        )
    }
    public handleConfirm = async () => {
        const { listHang, listChao, lan } = this.state;
        const { intl, dauthau: { dauThauId, nhaCungCapId } } = this.props;
        const arr = [];
        for(const s of listHang) {
            const a = {
                DauThauId: s.dauThauId,
                NhaCungCapId: s.nhaCungCapId,
                HangHoaId: s.hangHoaId,
                Id: s.id,
                MaHang: s.maHangNhaCungCap,
                XuatXu: s.xuatXu,
                NgayHetHan: s.ngayHetHan,
                DonGia: s.donGia.trim() !== "" ? parseInt(s.donGia.replace(/\./g, "")) : 0,
                LuongChao: s.luongChao.trim() !== "" ? parseInt(s.luongChao.replace(/\./g, "")) : 0,
                ThoiGianGiao: s.thoiGianGiao,
                MieuTa: s.mieuTa,
                Lan: lan
            }
            arr.push(a);
        }
        const resp = await baoGiaServices.insert(arr);
        const { code } = resp;
        if(code === 0) {
            await baoGiaServices.del(dauThauId, nhaCungCapId, lan - 1);
            const ar= [];
            for(const s of listChao) {
                if(s.maHang.trim() !== "" && s.tenHang.trim() !== "" && s.giaChao !== "") {
                    const a = {
                        DauThauId: dauThauId,
                        NhaCungCapId: nhaCungCapId,
                        MaHang: s.maHang,
                        TenHang: s.tenHang,
                        DonVi: s.donVi,
                        XuatXu: s.xuatXu,
                        NgayHetHan: s.ngayHetHan,
                        GiaChao: s.giaChao.trim() !== "" ? parseInt(s.giaChao.replace(/\./g, "")) : 0,
                        LuongChao: s.luongChao.trim() !== "" ? parseInt(s.luongChao.replace(/\./g, "")) : 0,
                        MieuTa: s.mieuTa,
                        Lan: lan
                    }
                    ar.push(a);
                }
            }
            if(ar.length > 0) {
                await chaoHangServices.insert(ar);
            }
            const a: any = {  
                icon: 'success',  
                text: intl.formatMessage({id: "lb117" }),
                timer: 2000
            };
            Swal.fire(a);
            this.setState({ final: true });
        }
    }
    public handlePrint = () => {
        const d = document.getElementById("btnPrint");
        if(d !== null)
            d.style.display = "none";
        const c = document.getElementById("divNav");
        if(c !== null)
            c.style.display = "none";
        window.print();
    }
    public render() {
        const { step, final } = this.state;
        const { intl } = this.props;
        return (
            <div className='wrapper'>
                <div className="content">
                    <Col className="mr-auto ml-auto" md="10">
                        <div className="wizard-container">
                            <div data-color="blue" className="card card-wizard active card">
                                { this.renderHeader() }
                                <div className="card-body" ref={this.docRef}>
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active">
                                            { step === 1 && this.renderOne() }
                                            { step === 2 && this.renderTwo() }
                                            { step === 3 && this.renderThree() }
                                            { step === 4 && this.renderFour() }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    { step > 1 && !final && <button type="button" className="btn-info btn btn-sm btn-secondary" onClick={this.changeStep.bind(this, -1)}>{ intl.formatMessage({ id: "lb21" }) }</button> }
                                    { step < 4 && !final && <button type="button" className="btn-info btn btn-sm btn-secondary" onClick={this.changeStep.bind(this, 1)}>{ intl.formatMessage({ id: "lb111" })}</button> }
                                    { step === 4 && !final && <button type="button" className="btn-info btn btn-sm btn-secondary" onClick={this.handleConfirm}>{ intl.formatMessage({ id: "lb30" })}</button> }
                                    { final && <button type="button" className="btn-info btn btn-sm btn-secondary" id='btnPrint' onClick={this.handlePrint}>{ intl.formatMessage({ id: "lb118" })}</button> }
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
                <ToastContainer position="top-center" hideProgressBar={true} autoClose={1500}></ToastContainer>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    const { authen } = state;
    return {
      dauthau: authen.dauthau
    }
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Bid));