import { legacy_createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { check } from '../redux/actions';

const sagaMiddleware = createSagaMiddleware();

export function configureStore() {
  const store = legacy_createStore(
    reducers,
    applyMiddleware(sagaMiddleware)
    
  );
  sagaMiddleware.run(sagas);
  store.dispatch(check());
  return store;
}
