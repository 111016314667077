export const utils = {
    formDateTimeYmd,
    formDateTimedmY,
    formatNumber,
    ddMMyyyytoDate,
    formDateTimedmYhms
}
function formDateTimeYmd (date: string) {
    try {
        let d: any = null;
        if (typeof date == 'string') {
            d = new Date(date);
        }
        else d = date;
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1;
        const curr_year = d.getFullYear();

        if (curr_date < 10) curr_date = "0" + curr_date;
        if (curr_month < 10) curr_month = "0" + curr_month;

        return curr_year + "-" + curr_month + "-" + curr_date;
    }
    catch { }   
    return null;
}
function formDateTimedmY (date: string) {
    try {
        let d: any = null;
        if (typeof date == 'string') {
            d = new Date(date);
        }
        else d = date;
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1;
        const curr_year = d.getFullYear();

        if (curr_date < 10) curr_date = "0" + curr_date;
        if (curr_month < 10) curr_month = "0" + curr_month;

        return curr_date + "/" + curr_month + "/" + curr_year;
    }
    catch { }   
    return date;
}
function formDateTimedmYhms(date: any) {
    try {
        let d: any = null;
        if (typeof date == 'string') {
            d = new Date(date);
        }
        else d = date;
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1;
        const curr_year = d.getFullYear();

        if (curr_date < 10) curr_date = "0" + curr_date;
        if (curr_month < 10) curr_month = "0" + curr_month;

        let hours: any = d.getHours()
        let minutes: any = d.getMinutes()
        let seconds: any = d.getSeconds();

        if (hours < 10)
            hours = "0" + hours;
        if (minutes < 10)
            minutes = "0" + minutes;
        if (seconds < 10)
            seconds = "0" + seconds;

        return curr_date + "/" + curr_month + "/" + curr_year + " " + hours + ":" + minutes + ":" + seconds;
    }
    catch { }   
    return null;
}
function formatNumber(number = 0, de = ".") {

    const r = new RegExp("\\B(?=(\\d{3})+(?!\\d))", 'g');
    return number.toString().replace(r, de ? de : ".")
}
function ddMMyyyytoDate(s: string) {
    let p: any = s.split("/");
    let d = null;
    try {
      d  = new Date(+p[2], p[1] - 1, +p[0]); 
    }
    catch{
        d = null;
    }
    return d;
}